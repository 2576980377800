import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next"
import { useLocalization } from "gatsby-theme-i18n"
import loadable from "@loadable/component";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import './assets/styles/_index.scss';
import FormFields from "../../forms/offplan_detail_vertical_form.json";
import FormFieldsAR from "../../forms/offplan_detail_vertical_form_AR.json";
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const Newsletter = loadable(() => import("../forms/default-form-layout"));
const OffPlanDescStatic = (props) => {

    const { t } = useTranslation()
    const { locale } = useLocalization()

    // Sticky scroll
    const [scroll, setScroll] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50)
        })
    }, [])
    // Sticky scroll

    const prop_url = props?.pageurl
    const prop_address = props?.display_address
    const prop_img_url = props?.propImg && props.propImg?.length > 0 ? props.propImg[0].url : ""

    return (
        <section className="off-plan-desc-wrapper" id="description">
            <Container>
                <Row>
                    <Col xl={7}>
                        <div className="desc-key-grid-wrapper off-plan-desc-details">
                            {props.completion_date &&
                                <div>
                                    <div class="desc-key-wrapper">
                                        <div class="desc-key-title">{t("compl_date")}</div>
                                        <div class="desc-key-text">{props.completion_date}</div>
                                    </div>
                                </div>
                            }
                            {props.location &&
                                <div>
                                    <div class="desc-key-wrapper">
                                        <div class="desc-key-title">{t("location")}</div>
                                        <div class="desc-key-text">{props.location}</div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="border-line key-details-lg"></div>

                        {props?.description &&
                            <div className="off-plan-desc off-plan-desc-static">
                                <ContentModule Content={props?.description?.data?.description} />
                            </div>
                        }
                    </Col>
                    <Col xl={1}></Col>
                    <Col xl={4}>
                        <div className={`off-plan-detail-sidebar position-sticky ${scroll ? "scrolled" : ""}`} id="off-plan-detail-sidebar">
                            <div className="news-detail-subscribe-wrapper">
                                <div className="news-detail-subscribe-content">
                                    <h5>{t("register_int")}</h5>
                                    <p>{t("share_your")}</p>
                                    <Newsletter
                                        formStyle={"vertical"}
                                        fields={locale == "ar" ? FormFieldsAR : FormFields}
                                        classname="get-started-form newsletter"
                                        formtagclassname=""
                                        prop_url={`${prop_url}`}
                                        prop_address={`${prop_address}`}
                                        prop_img_url={`${prop_img_url}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default OffPlanDescStatic